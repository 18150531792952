import React, { useState, useEffect } from 'react';
import { Input, Button, Table, Spin, Descriptions, Modal, Layout, Menu, Row, Col, Typography, Upload, Form, Checkbox } from 'antd';
import axios from 'axios';
import Login from './Login';
import { message, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { parseISO, format } from 'date-fns'

import {
  FileSearchOutlined,
  LogoutOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import jwtDecode from 'jwt-decode';

import './App.css';

import TilitysTapahtumat from './TilitysTapahtumat'

const { TextArea } = Input
const { Header, Content } = Layout;
const { Title } = Typography;

const backendApiUrl = process.env.REACT_APP_API_URL

message.config({
  top: 80
})


const ModalWithMessage = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [supportMessage, setSupportMessage] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    // Tässä voit käsitellä viestin lähettämistä, esimerkiksi lähettää sen palvelimelle.
    // Tässä käytetään vain konsolin tulostusta esimerkkinä.
    console.log('Lähetetty viesti:', supportMessage);
    try {
      const response = await axios.post(`${backendApiUrl}/api/message`, {
        id: id,
        message: supportMessage,
      })
      console.log(response)
      message.success('Viesti lähetetty onnistuneesti');
    } catch (error) {
      console.error(error)
      message.error('Viestin lähetys epäonnistui');
    } finally {
      setIsModalVisible(false);
    }

  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Lähetä palvelupyyntö
      </Button>
      <Modal
        title={`Pyyntö liittyen asiakkaaseen ${id}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Lähetä'
        cancelText='Peruuta'
        maskClosable={false}
      >
        <TextArea
          placeholder="Kirjoita viesti"
          autoSize={{ minRows: 2 }}
          value={supportMessage}
          onChange={(e) => setSupportMessage(e.target.value)}
        />
      </Modal>
    </div>
  );
};


const Ulosotto = ({ admin }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('')
  const columns = [
    {
      title: 'Tiedostonimi',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Kuvaus / kommentti',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Luotu',
      key: 'created_at',
      render: (text, record) => (
        <p>{format(parseISO(record.created_at), 'dd.MM.yyyy HH.mm')}</p>
      ),
    },
    {
      title: 'Lataa',
      key: 'download',
      render: (text, record) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => downloadFile(record.id, record.original)}
        >
          Lataa
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/files?type=ulosotto`); // Korvaa tämä oikealla reitillä palvelimellasi
      setFileList(response.data);
    } catch (error) {
      console.error('Virhe haettaessa tiedostoja: ', error);
    }
  };

  const handleFileUpload = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData()
      formData.append('note', note)
      formData.append('type', 'ulosotto')
      formData.append('file', file);

      await axios.post(`${backendApiUrl}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setNote('')
      message.success('Tiedosto ladattu onnistuneesti');
      fetchData();
    } catch (error) {
      console.error('Virhe tiedoston lataamisessa: ', error);
      message.error('Tiedoston lataaminen epäonnistui');
    } finally {
      setLoading(false)
    }
  };

  const downloadFile = async (id, original) => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/download?id=${id}`, {
        responseType: 'blob',
      });
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', original);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Virhe tiedoston lataamisessa: ', error);
      message.error('Tiedoston lataaminen epäonnistui');
    }
  };

  const inputOnChange = (e) => {
    setNote(e.target.value)
  }

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div>
      <Title level={5}>Ulosotto tilitykset</Title>
      <Table
        dataSource={fileList}
        columns={columns}
        rowKey={(record) => record.id}
      />
      {admin ? <>
        <Space>
          <Form.Item name="note" label="Kuvaus / kommentti (syötä ennen tiedoston valintaa)">
            <Input value={note} onChange={inputOnChange} />
          </Form.Item>
          <Form.Item name="upload">
            <Upload
              customRequest={async ({ file }) => handleFileUpload(file)}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} loading={loading}>
                Lataa tiedosto
              </Button>
            </Upload>
          </Form.Item>
        </Space>
      </> : <></>}
    </div>
  );
};

const Suoramaksut = ({ admin }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('')
  const columns = [
    {
      title: 'Tiedostonimi',
      dataIndex: 'original',
      key: 'original',
    },
    {
      title: 'Kuvaus / kommentti',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Luotu',
      key: 'created_at',
      render: (text, record) => (
        <p>{format(parseISO(record.created_at), 'dd.MM.yyyy HH.mm')}</p>
      ),
    },
    {
      title: 'Lataa',
      key: 'download',
      render: (text, record) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => downloadFile(record.id, record.original)}
        >
          Lataa
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/files?type=suoramaksu`); // Korvaa tämä oikealla reitillä palvelimellasi
      setFileList(response.data);
    } catch (error) {
      console.error('Virhe haettaessa tiedostoja: ', error);
    }
  };

  const handleFileUpload = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData()
      formData.append('note', note)
      formData.append('type', 'suoramaksu')
      formData.append('file', file);

      await axios.post(`${backendApiUrl}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setNote('')
      message.success('Tiedosto ladattu onnistuneesti');
      fetchData();
    } catch (error) {
      console.error('Virhe tiedoston lataamisessa: ', error);
      message.error('Tiedoston lataaminen epäonnistui');
    } finally {
      setLoading(false)
    }
  };

  const downloadFile = async (id, original) => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/download?id=${id}`, {
        responseType: 'blob',
      });
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', original);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Virhe tiedoston lataamisessa: ', error);
      message.error('Tiedoston lataaminen epäonnistui');
    }
  };

  const inputOnChange = (e) => {
    setNote(e.target.value)
  }

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div>
      <Title level={5}></Title>
      <Table
        dataSource={fileList}
        columns={columns}
        rowKey={(record) => record.id}
      />
      {admin ? <>
        <Space>
          <Form.Item name="note" label="Kuvaus / kommentti (syötä ennen tiedoston valintaa)">
            <Input value={note} onChange={inputOnChange} />
          </Form.Item>
          <Form.Item name="upload">
            <Upload
              customRequest={async ({ file }) => handleFileUpload(file)}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} loading={loading}>
                Lataa tiedosto
              </Button>
            </Upload>
          </Form.Item>
        </Space>
      </> : <></>}
    </div>
  );
};

const Tilitys = ({ admin }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('')
  const columns = [
    {
      title: 'Tiedostonimi',
      dataIndex: 'original',
      key: 'original',
    },
    {
      title: 'Kuvaus / kommentti',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Luotu',
      key: 'created_at',
      render: (text, record) => (
        <p>{format(parseISO(record.created_at), 'dd.MM.yyyy HH.mm')}</p>
      ),
    },
    {
      title: 'Lataa',
      key: 'download',
      render: (text, record) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => downloadFile(record.id, record.original)}
        >
          Lataa
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/files?type=tilitys`); // Korvaa tämä oikealla reitillä palvelimellasi
      setFileList(response.data);
    } catch (error) {
      console.error('Virhe haettaessa tiedostoja: ', error);
    }
  };

  const handleFileUpload = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData()
      formData.append('note', note)
      formData.append('type', 'tilitys')
      formData.append('file', file);

      await axios.post(`${backendApiUrl}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setNote('')
      message.success('Tiedosto ladattu onnistuneesti');
      fetchData();
    } catch (error) {
      console.error('Virhe tiedoston lataamisessa: ', error);
      message.error('Tiedoston lataaminen epäonnistui');
    } finally {
      setLoading(false)
    }
  };

  const downloadFile = async (id, original) => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/download?id=${id}`, {
        responseType: 'blob',
      });
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', original.replace('.dat', '.txt'));
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Virhe tiedoston lataamisessa: ', error);
      message.error('Tiedoston lataaminen epäonnistui');
    }
  };

  const inputOnChange = (e) => {
    setNote(e.target.value)
  }

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div>
      <Title level={5}>Tilitykset</Title>
      <Table
        dataSource={fileList}
        columns={columns}
        rowKey={(record) => record.id}
      />
      {admin ? <>
        <Space>
          <Form.Item name="note" label="Kuvaus / kommentti (syötä ennen tiedoston valintaa)">
            <Input value={note} onChange={inputOnChange} />
          </Form.Item>
          <Form.Item name="upload">
            <Upload
              customRequest={async ({ file }) => handleFileUpload(file)}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} loading={loading}>
                Lataa tiedosto
              </Button>
            </Upload>
          </Form.Item>
        </Space>
      </> : <></>}
    </div>
  );
};

const Tapahtumat = ({ admin }) => {
  const [tapahtumatList, setTapahtumatList] = useState([]);
  // const [loading, setLoading] = useState(false);

  const [filteredStatus, setFilteredStatus] = useState(null)

  const handleCheckboxChange = async (id, checked) => {
    if (admin) {
      try {
        const response = await axios.post(`${backendApiUrl}/api/tapahtumat`, { id: id, status: checked }); // Korvaa tämä oikealla reitillä palvelimellasi
        console.log(response)
        const updatedData = tapahtumatList.map((item) =>
          item.id === id ? { ...item, imported: checked } : item
        );
        setTapahtumatList(updatedData);
      } catch (error) {
        console.error(error)
      }
    }


  }

  const adminColumns = [
    {
      title: 'Nimi',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Viite',
      dataIndex: 'ref',
      key: 'ref',
    },
    {
      title: 'Summa',
      dataIndex: 'sum',
      key: 'sum',
    },
    {
      title: 'Luotu',
      key: 'created_at',
      render: (text, record) => (
        <>{format(parseISO(record.created_at), 'dd.MM.yyyy HH.mm')}</>
      ),
    },
    {
      title: 'Tila',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'True', value: true },
        { text: 'False', value: false },
      ],
      onFilter: (value, record) => record.imported === value,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Space direction="vertical">
            <Checkbox
              checked={selectedKeys.includes(true)}
              onChange={() => setSelectedKeys([true])}
            >
              Kirjattu
            </Checkbox>
            <Checkbox
              checked={selectedKeys.includes(false)}
              onChange={() => setSelectedKeys([false])}
            >
              Ei kirjattu
            </Checkbox>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                  //setFilteredStatus(selectedKeys)
                }}
                size="small"
              >
                Ok
              </Button>
              <Button onClick={clearFilters} size="small">
                Tyhjä
              </Button>
            </Space>
          </Space>
        </div>
      ),
      render: (text, record) => (
        <Checkbox
          checked={record.imported}
          onChange={(e) => handleCheckboxChange(record.id, e.target.checked)}
        />
      ),
    },
  ]

  const columns = [
    {
      title: 'Nimi',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Viite',
      dataIndex: 'ref',
      key: 'ref',
    },
    {
      title: 'Summa',
      dataIndex: 'sum',
      key: 'sum',
    },
    {
      title: 'Luotu',
      key: 'created_at',
      render: (text, record) => (
        <>{format(parseISO(record.created_at), 'dd.MM.yyyy HH.mm')}</>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/tapahtumat`)
      setTapahtumatList(response.data);
    } catch (error) {
      console.error('Virhe haettaessa tiedostoja: ', error);
    }
  };



  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div>
      <Title level={5}>Tilitapahtumat</Title>
      <p>Huom. tapahtumat eivät kirjaannu vielä tosiaikaisesti reskontraan</p>
      {admin ?
        <Table
          dataSource={tapahtumatList}
          columns={adminColumns}
          rowKey={(record) => record.id}
          size='small'
          pagination={{
            pageSizeOptions: ['20', '50', '100'],
            showSizeChanger: true,
            defaultPageSize: 50,
          }}
        /> :
        <Table
          dataSource={tapahtumatList}
          columns={columns}
          rowKey={(record) => record.id}
          size='small'
          pagination={{
            pageSizeOptions: ['20', '50', '100'],
            showSizeChanger: true,
            defaultPageSize: 50,
          }}
        />
      }
    </div>
  )
}


function App() {
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Alusta isLoading-tila
  const [user, setUser] = useState(null)
  const [admin, setAdmin] = useState(false)
  const [currentPage, setCurrentPage] = useState('home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLogout = () => {
    localStorage.removeItem('jwtToken')
    setUser(false)
    setSearchValue('')
    setSearchResult([])
    setAdmin(false)
    setCurrentPage('home')
  };

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Aika sekunteina

        if (decodedToken.exp < currentTime) {
          // Token on vanhentunut
          console.log('Token on vanhentunut')
          setUser(false)
          localStorage.removeItem('jwtToken')
          // Voit tässä pyytää käyttäjää kirjautumaan uudelleen tai tekemään muuta sopivaa toimintaa
        } else {
          // Token on voimassa
          if (decodedToken.admin) {
            setAdmin(true)
          }
          console.log('Token on voimassa')
          // Voit jatkaa toimintaa, joka vaatii voimassa olevan tokenin
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

          // Tarkista käyttäjätietojen saatavuus

          setUser(true)
        }
      } catch (error) {
        console.error('Virhe tokenin tarkistamisessa:', error)
        setUser(false)
        localStorage.removeItem('jwtToken')
      }
    } else {
      // Tokenia ei ole saatavilla, joten käyttäjä ei ole kirjautunut sisään
      console.log('Tokenia ei ole saatavilla, käyttäjä ei ole kirjautunut sisään')
      setUser(false)
    }
    setLoading(false)
  }, []);


  const handleSearch = async () => {
    setIsLoading(true); // Aseta isLoading arvoksi true haku alkaessa

    try {
      const response = await axios.get(`${backendApiUrl}/api/search`, {
        params: { id: searchValue },
      });
      console.log(response)
      if (response.data.length === 0) {
        message.info('Ei tuloksia, yritä uudelleen')
      }
      setSearchResult(response.data);
    } catch (error) {
      console.error('Haku epäonnistui:', error);
      message.error('Haku epäonnistui');

      setSearchResult([]);
    } finally {
      setIsLoading(false); // Aseta isLoading arvoksi false, kun haku on valmis
    }
  }

  const handleLogin = async (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    setUser(true)

    const decodedToken = jwtDecode(token);
    if (decodedToken.admin) {
      setAdmin(true)
    }
  }

  const isSearchDisabled = searchValue.length < 4;

  const columns = [
    {
      title: 'Laskunumero',
      dataIndex: 'InvoiceNumber',
      key: 'InvoiceNumber',
    },
    {
      title: 'Laskupäivä ',
      dataIndex: 'Invoicedate',
      key: 'Invoicedate',
    },
    {
      title: 'Laskun tila',
      dataIndex: 'InvoiceStatus',
      key: 'InvoiceStatus',
    },
    {
      title: 'Kokonaissumma',
      dataIndex: 'InvoiceSum',
      key: 'InvoiceSum',
    },
    {
      title: 'Avoinna',
      dataIndex: 'OpenSum',
      key: 'OpenSum',
    },
  ];

  const paymentColumns = [
    {
      title: 'Laskunumero',
      dataIndex: 'InvoiceNumber',
      key: 'InvoiceNumber',
    },
    {
      title: 'Viitenumero',
      dataIndex: 'Ref',
      key: 'Ref',
    },
    {
      title: 'Päiväys',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Summa',
      dataIndex: 'Sum',
      key: 'Sum',
    }
  ];

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.3)', // Läpinäkyvä tausta
          zIndex: 9999, // Varmista, että se on päällimmäisenä
        }}
      >
        <Spin size="large" tip="Ladataan..." spinning={loading} />
      </div>
    )

  }

  const centerStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  };

  const rightStyle = { position: 'absolute', top: 0, right: 0 };

  if (user) {
    return (
      <Layout className="layout">
        <Header>
          <Menu style={centerStyle} theme="dark" mode="horizontal" selectedKeys={[currentPage]}>
            <Menu.Item key="home" icon={<FileSearchOutlined />} onClick={() => handlePageChange('home')}>
              Asiakas haku
            </Menu.Item>
            <Menu.Item key="profile" icon={<FileTextOutlined />} onClick={() => handlePageChange('profile')} >
              Ulosotto tilitykset
            </Menu.Item>
            {admin ?
              <Menu.Item key="suoramaksut" icon={<FileTextOutlined />} onClick={() => handlePageChange('suoramaksut')} >
                Suoramaksut
              </Menu.Item>
              : <></>}
            {admin ?
              <Menu.Item key="tilitys" icon={<FileTextOutlined />} onClick={() => handlePageChange('tilitys')} >
                Tilitys
              </Menu.Item>
              : <></>}
            <Menu.Item key="tapahtumat" icon={<FileTextOutlined />} onClick={() => handlePageChange('tapahtumat')} >
              Tapahtumat
            </Menu.Item>
            {admin ?
              <Menu.Item key="tilitystapahtumat" icon={<FileTextOutlined />} onClick={() => handlePageChange('tilitystapahtumat')} >
                Tilitys Tapahtumat
              </Menu.Item>
              : <></>}
            <Menu.Item key="logout" style={rightStyle}>
              <Button
                type="primary"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
                style={{ margin: '16px' }}
              >
                Kirjaudu ulos
              </Button>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ padding: '24px' }}>
          {currentPage === 'home' && <div>
            <div>
              <Title level={5}>Asiakas haku</Title>
              <Form.Item name="asiakasnumero_haku" label="Asiakasnumero">
                <Input
                  placeholder="123456"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{ maxWidth: '200px' }}
                />
              </Form.Item>

              <Form.Item name="asiakasnumero_haku_button">
                <Button type="primary" onClick={handleSearch} loading={isLoading} disabled={isSearchDisabled}>
                  Etsi
                </Button>
              </Form.Item>
              {searchResult?.customer ? <div style={{ padding: '20px' }}>
                <Descriptions bordered items={searchResult.customer} column={1} size='small' />
                <Row gutter={16}> {/* gutter määrittää sarakkeiden välin */}
                  <Col span={12}>
                    {/* Tähän tulee ensimmäinen sarake */}
                    <div style={{ padding: '16px' }}>
                      <Title level={5}>Avoimet laskut</Title>
                      <Table dataSource={searchResult.invoices} columns={columns} size='small' />
                    </div>
                  </Col>
                  <Col span={12}>
                    {/* Tähän tulee toinen sarake */}
                    <div style={{ padding: '16px' }}>
                      <Title level={5}>Suoritukset</Title>
                      <Table dataSource={searchResult.paymentList} columns={paymentColumns} size='small' />
                    </div>
                  </Col>
                </Row>
                <ModalWithMessage id={searchResult?.customer[0].children} />
              </div> : <></>}

            </div>
          </div>}
          {currentPage === 'profile' && <div>
            <Ulosotto admin={admin} />
          </div>}
          {currentPage === 'suoramaksut' && <div>
            <Suoramaksut admin={admin} />
          </div>}
          {currentPage === 'tilitys' && <div>
            <Tilitys admin={admin} />
          </div>}
          {currentPage === 'tapahtumat' && <div>
            <Tapahtumat admin={admin} />
          </div>}
          {currentPage === 'tilitystapahtumat' && <div>
            <TilitysTapahtumat admin={admin} />
          </div>}
        </Content>
      </Layout>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <Login handleLogin={handleLogin} />
      </header>
    </div>
  );
}

export default App;
