import React, { useState, useEffect } from 'react';
import { Input, Button, Table, Spin, Descriptions, Modal, Layout, Menu, Row, Col, Typography, Upload, Form, Checkbox } from 'antd';
import axios from 'axios';
import Login from './Login';
import { message, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { parseISO, format } from 'date-fns'

import {
  FileSearchOutlined,
  LogoutOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import jwtDecode from 'jwt-decode';

import './App.css';

const { TextArea } = Input
const { Header, Content } = Layout;
const { Title } = Typography;

const backendApiUrl = process.env.REACT_APP_API_URL

message.config({
  top: 80
})


function TilitysTapahtumat() {
  const [tapahtumatList, setTapahtumatList] = useState([]);
  // const [loading, setLoading] = useState(false);

  const [filteredStatus, setFilteredStatus] = useState(null)


  const columns = [
    {
      title: 'Asiakasnumero',
      dataIndex: 'customer_number',
      key: 'customer_number',
    },
    {
      title: 'Asiakas',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Summa yhteensä (ei kuluja)',
      dataIndex: 'sum_without_fees',
      key: 'sum_without_fees',
    },
    {
      title: 'Pääoma',
      dataIndex: 'sum_capital',
      key: 'sum_capital',
    },
    {
      title: 'Korko',
      dataIndex: 'sum_interest',
      key: 'sum_interest',
    },
    {
      title: 'Laskun numero',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
    },
    {
      title: 'Laskun viite',
      dataIndex: 'ref_netvisor',
      key: 'ref_netvisor',
    },
    {
      title: 'Viite',
      dataIndex: 'ref',
      key: 'ref',
    },
    {
      title: 'Ryhmä',
      dataIndex: 'tilitys_group',
      key: 'tilitys_group',
    },
    {
      title: 'Luotu',
      key: 'created_at',
      render: (text, record) => (
        <>{format(parseISO(record.created_at), 'dd.MM.yyyy HH.mm')}</>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/tilitys-tapahtumat`)
      setTapahtumatList(response.data);
    } catch (error) {
      console.error('Virhe haettaessa tiedostoja: ', error);
    }
  };

  

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div>
      <Table
        dataSource={tapahtumatList}
        columns={columns}
        rowKey={(record) => record.id}
        size='small'
        pagination={{
          pageSizeOptions: ['20', '50', '100'],
          showSizeChanger: true,
          defaultPageSize: 50,
        }}
      /> 
    </div>
  )
}

export default TilitysTapahtumat;
